import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["image"];

  maximise() {
    const src = this.imageTarget.src;

    const container = document.createElement("div");
    container.classList.add("trans", "maximised-image-container");

    const img = document.createElement("img");
    img.src = src;
    container.appendChild(img);

    const caption = document.createElement("p");
    caption.classList.add("caption", "text-center");
    caption.innerHTML = this.imageTarget.alt;
    container.appendChild(caption);

    const closeButton = document.createElement("button");
    closeButton.classList.add("close-button");
    closeButton.innerHTML = "&times;";
    container.appendChild(closeButton);

    closeButton.addEventListener("click", () => {
      container.classList.add("trans");
      this.addEvents();
      setTimeout(() => {
        container.remove();
      }, 300);
    });

    document.body.appendChild(container);

    this.removeEvents();

    setTimeout(() => {
      container.classList.remove("trans");
    }, 10);
  }

  //  remove pointer events to the images this
  //  will prevent the user from clicking on the images
  // and openning more than one maximised image
  removeEvents() {
    const images = document.querySelectorAll(".image-container");
    images.forEach((image) => {
      image.style.pointerEvents = "none";
    });
  }

  // Bring back pointer events to the images
  addEvents() {
    const images = document.querySelectorAll(".image-container");
    images.forEach((image) => {
      image.style.pointerEvents = "auto";
    });
  }
}
