/*
A simple JS file to make collapsible sections in HTML.

There are three elements that must be present for this to work:

Container, indicated with the data-collapsible attribute
Trigger, indicated with the data-collapsible-trigger attribute
Target, indicated with the data-collapsible-target attribute
Consider the following HTML

<!-- Container -->
<div class='container' data-collapsible>

  <!-- Trigger -->
  <button data-collapsible-trigger >Open</button>

  <!-- Target -->
  <div data-collapsible-target > Content </div>

  <!-- Target -->
  <div data-collapsible-target > More Content </div>
</div>

To hide it in the first place, you should set up CSS for the target like this:

[data-collapsible-target] {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

When a target in the container is clicked, it will change the style.maxHeight on all target elements to the scrollHeight, i.e the height of the element regardless of what is shown on screen.

When a target is clicked again, it will set the style.maxHeight to null.

You can use any number of targets in a container.

You can use any number of containers on a page.
*/

const collapsible = () => {
  const containers = document.querySelectorAll("[data-collapsible]");
  for(let i = 0; i < containers.length; i++) {
    const targets = containers[i].querySelectorAll("[data-collapsible-target]");
    const trigger = containers[i].querySelector("[data-collapsible-trigger]");

    trigger.addEventListener("click", () => {
      for(let i = 0; i < targets.length; i++) {
        const target = targets[i];

        target.style.transition = "max-height 0.5s ease-in-out";

        if(target.style.maxHeight) {
          target.style.maxHeight = null;
        } else {
          target.style.maxHeight = target.scrollHeight + "px";
        }
      }
    });
  }
}

document.addEventListener("turbolinks:load", collapsible);

export default collapsible;