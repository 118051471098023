import Swiper, {Autoplay} from 'swiper';


document.addEventListener('turbolinks:load', () => {
  Swiper.use([Autoplay]);
  const swiper = new Swiper('.home-swiper', {
    loop: true,
    autoplay: {
      delay: 2000,
    }
  });


});