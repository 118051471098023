import mapboxgl from "mapbox-gl";
import * as mapData from "./mapData";
import * as vis from "./visibility";
import * as mapHelpers from "./mapInteractionHelpers";
import { allowLocationAddition } from "./mapAddLocationHelper";
import close from "./close.svg";
import { parameterize } from "./parameterize";


let mapContainer;
let allPeriods = [];
export let map;
export let addLocationButton;


// detect map and setup
var initMap = async () => {
  if (mapboxgl.supported()) {
    mapContainer = document.getElementById("map");
    allPeriods = await getAllPeriods().then((periods) => periods.reverse());
    if (mapContainer) {
      // stop clicks on map until we have zoomed out
      mapContainer.style.pointerEvents = "none";
      map = mapData.setupMap("map");
      map.on('load', handleMapLoad);
    }
  }
};


const getAllPeriods = async () => {
  try {

    let response = await fetch("/periods.json");
    let periods = await response.json();
    return periods.features.map((period) => period.name);
  } catch (error) {
    console.error(error);
    return [];
  }
};


const handleMapLoad = () => {
  setupAddLocation();
  mapData.addGeoJsonLayer(map, '/locations.json');
  mapHelpers.addNavigationControls();
  map.on('click', 'locations-points', handleLocationClick);
  mapHelpers.setMapZoom();
  loadSelectedLocation();
  // re-enable clicks on map
  setTimeout(() => {
    mapContainer.style.pointerEvents = "auto";
  }, 1500);
};

const loadSelectedLocation = () => {
  const cl = document.body.classList;
  if (!cl.contains("locations") && !cl.contains('show')) return;
  const dataContainer = document.querySelector('meta[type="location-data"]');
  if (!dataContainer) return;

  const data = JSON.parse(dataContainer.getAttribute('content'));
  mapHelpers.mapActivateFeature(data);
}

const handleLocationClick = async (e) => {
  const feature = e.features[0];
  if (!feature) return;

  const isKeySite = feature.properties.key_site;
  // get the key site data
  const html = isKeySite ? keySitePopup(feature.properties) : normalLocationPopup(feature.properties);
  const container = document.getElementById('popup-container');

  // get the popup container
  if (!container) {
    console.error('no popup container');
    return;
  }

  container.innerHTML = html;
  // set its inner HTML to the key site popup
  container.firstElementChild.classList.remove('trans');

  // we need to add the event listener for data-toggler etc.
  $(container).foundation();

}


const DEP_handleLocationClick = async (e) => {
  const feature = e.features[0];
  mapHelpers.mapActivateFeature(feature);
  mapHelpers.fireLocationLink(feature);
  mapHelpers.showTitleSection(feature);
  // hide locations layer
  map.setLayoutProperty('locations-points', 'visibility', 'none');

  // restrictBoundsToCurrent
};

const setupAddLocation = () => {
  addLocationButton = document.getElementById("addLocation");

  if (addLocationButton) {
    addLocationButton.innerHTML = "Add Location";
    vis.hideById("info");
    addLocationButton.addEventListener("click", allowLocationAddition);
  }
}

document.addEventListener("turbolinks:load", initMap);

const handleBackButtonClick = (e) => {
  if (e.target.id === "back-to-map" || e.target.parentElement.id === "back-to-map") {
    const shouldWait = e.target.classList.contains("wait") || e.target.parentElement.classList.contains("wait");
    mapHelpers.handleBackClick(shouldWait, map);
    map.setLayoutProperty('locations-points', 'visibility', 'visible');
  }
}

document.addEventListener('click', handleBackButtonClick)



const keySitePopup = (data) => {
  if (data.image) data.image = JSON.parse(data.image);
  data.periods = JSON.parse(data.periods);
  let html = `<div class='inline-block secondary-bg mp border-radius accept-click relative toggle-trans trans' data-toggler='.trans' id='popup' style='max-width: 500px; max-height: 500px; overflow-y: auto;'>
  <img class="close-popup absolute top right xsm-small-only mm-medium-up hand-on-hover" style="width: 20px;height:20px;z-index:10000;" data-toggle="popup" alt="close" src="${close}" />
    <div class="grid grid-x grid-margin-x">
      <div class="periods-highlight cell small-2 grid-y white">
        ${periodsHighlight(data.periods)}
      </div>
      <div class="cell small-10">
        <h3 class='white nmb'>
          ${data.title}
        </h3>
        <h4 class='turquoise smb'>Heritage Site</h4>
        <div class='white mmb'>
          ${data.blurb}
        </div>
        ${data.image ? `<img alt="${data.image.name}" style="max-height:300px;" class="block mmb" src="${data.image.url}" />` : ``}
        <div class="flex">
          <a class="round-lg margin-0-auto white-bg medium button darker-blue" href="/places/${data.id}" target="_blank">Visit Site</a>
        </div>
      </div>
    </div>
  </div>`
  return html;
}


const periodsHighlight = (periods) => {
  // loop through all periods and check if they are in the periods array
  periods = periods.map((period) => period.name);
  let availablePeriods = allPeriods.filter((period) => periods.includes(period));
  // build html have a column of periods which are boxes if the period is in the available periods array color it green else transparent with green border

  let html = `${allPeriods.map((period) => {

    let border = `border-${parameterize(period)}`

    let bgColor = availablePeriods.includes(period) ? `bg-${parameterize(period)}` : '';

    return `<div class="${bgColor} ${border} popup-period-highlight">
    </div>
    `
  })}`.split(',').join('');

  return html
}

const normalLocationPopup = (data) => {
  if (data.image) data.image = JSON.parse(data.image);


  return `<div class='inline-block white-bg dark-blue mp border-radius accept-click relative toggle-trans trans' data-toggler='.trans' id='popup' style='max-width: 500px; max-height: 500px; overflow-y: auto;'>
        <img class="close-popup absolute top right xsm-small-only mm-medium-up hand-on-hover" style="width: 20px;height:20px;z-index:10000;" data-toggle="popup" alt="close" src="${close}" />
        <h3 class='nmb'>
          ${data.title}
        </h3>
        <div class='mmb'>
          ${data.blurb}
        </div>
        ${data.image ? `<img alt="${data.image.name}" style="max-height:300px;" class="block mmb" src="${data.image.url}" />` : ``}
  </div>`
}