import mapboxgl from 'mapbox-gl';

var mapStyle = 'mapbox://styles/icecreamneil/cl3iinwjy000w16mrni3no8zs';

export const center = [-6.10463, 56.73458];

const TOKEN = 'pk.eyJ1IjoiaWNlY3JlYW1uZWlsIiwiYSI6ImNqOTc5OTUzMTBiNzIycXF0bjB1bjFiOTYifQ.K2mrWaQZhVc7rLPLI5oAsw';

// export const bounds = new mapboxgl.LngLatBounds(
//   [ -6.406584250319071, 56.56114540153584],
//   [ -5.659534746585166, 56.88287573907215]
// );

export const bounds = new mapboxgl.LngLatBounds(
  [ -6.501888801733543, 56.35247058341139 ],
  [ -5.712981474310283, 57.19443707937586]
);

export const desktopView = new mapboxgl.LngLatBounds(
  [ -6.266478971721881, 56.63288241585923 ],
  [ -5.890256285571581, 56.807027120853576 ]
)

export const mobileView = new mapboxgl.LngLatBounds(
  [ -6.283035040007576, 56.52012909881924 ],
  [ -5.9235513640331305, 56.904431039954346 ]
);
  

export const mapOptions = {
  style: mapStyle,
  // zoom: 11.5,
  zoom: 15,
  center: center,
  pitch: 0,
  minZoom: 5,
  maxZoom: 20,
  maxBounds: bounds,
  bearing: 0,
  attributionControl: false,
}


export const setupMap = function (container, options = undefined) {
  if (options) combine(mapOptions, options); // Merge new options if provided

  mapOptions.container = container;
  mapboxgl.accessToken = TOKEN;

  const map = new mapboxgl.Map(mapOptions);
  // Setup custom Attribution ctrl
  map.addControl(new mapboxgl.AttributionControl({ compact: false }));
  return map;
}


// Combine objects, overriding old props with new
const combine = (orig, nu) => {
  Object.keys(orig).forEach(k => {
    if (nu.hasOwnProperty(k) && nu[k]) orig[k] = nu[k];
  })
}


export const addControls = (map, position = 'top-right') => {
  const ctrl = new mapboxgl.NavigationControl;
  map.addControl(ctrl, position);
}


export const addGeoJsonLayer = (map, dataOrUrl) => {
  map.addSource('locations', {
    "type": "geojson",
    "data": dataOrUrl
  });

  map.addLayer({
    "id": 'locations-points',
  "type": "symbol",
    "source": 'locations',
    "layout": {
      "icon-image": "{icon}",
      "icon-anchor": "bottom",
      "symbol-z-order": "viewport-y",
      // "icon-size":  ['*', ['get', 'icon_size'], 1],
      "icon-size":  ['get', 'icon_size'],
      "icon-allow-overlap": true,
    },
    paint: {
      "text-color": "#ffffff",
    },
    'filter': ['==', '$type', 'Point']
  })
  
  map.addLayer({
    "id": 'locations-text',
    "type": "symbol",
    "source": 'locations',
    "layout": {
      'text-field':['format',
        ['get', 'title'],
        { 'font-scale': 1 },
      ],
      "text-font": ["Space Mono Regular", "Open Sans Semibold", "Arial Unicode MS Bold"],
      'text-variable-anchor': ["top"],
      'text-radial-offset':  0.5,
      'text-justify': 'center',
      "text-allow-overlap": false,
      "symbol-z-order": "viewport-y",
    },
    "paint": {
      "text-color": "#304a63",
      "text-halo-width": 1,
      "text-halo-color": "#fff"
    },
    'filter': ['==', '$type', 'Point']
  })
}



export const zoomToRoute = (map, route) => {
  const { coordinates } = route.features[0].geometry;

  setTimeout(
    () => map.easeTo({
      center: coordinates[0],
      zoom: 12.8,
      duration: 1000
    }),
    250
  );
}



export const setupPopup = (map, layer) => {
  map.on('click', layer, (e) => {
    const { properties } = e.features[0];
    const { coordinates } = e.features[0].geometry

    // TAKEN from: https://docs.mapbox.com/mapbox-gl-js/example/popup-on-click/
    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    const html = createPointHTML(properties)

    // render HTML into Popup
    new mapboxgl.Popup({ offset: 15 })
      .setLngLat(coordinates)
      .setDOMContent(html)
      .addTo(map);
  })
}


const createPointHTML = (properties) => {
  const container = document.createElement('div');

  const title = document.createElement('h5');
  title.innerHTML = properties.title;

  container.appendChild(title);

  const buttonContainer = document.createElement('div');
  buttonContainer.classList.add('flex-container', 'align-center');

  const link = document.createElement('a');
  link.href = JsRoutesRails.trail_point_path({
    id: properties.id,
    trail_id: properties.trail_id,
  });

  const button = document.createElement('button');
  button.classList.add('button', 'expanded');
  button.innerHTML = 'View'

  // put button in link, then link in container
  link.appendChild(button);
  buttonContainer.appendChild(link);

  const editButton = document.createElement('button');
  editButton.classList.add('button', 'expanded');
  editButton.innerHTML = 'Edit';

  const editLink = document.createElement('a');
  editLink.classList.add('sml')
  editLink.href = JsRoutesRails.edit_trail_point_path({
    id: properties.id,
    trail_id: properties.trail_id,
  });

  // put button in link, then link in container
  editLink.appendChild(editButton);
  if (onEditTrailPage()) buttonContainer.appendChild(editLink);

  container.appendChild(buttonContainer);
  return container;
}


// Extract/Format Coordinates from obj
// Multiple conditions allow for 'click' and marker objects
export const getCoords = (object) => {
  const { lngLat, _lngLat } = object;
  if (lngLat) return [lngLat.lng, lngLat.lat];
  if (_lngLat) return [_lngLat.lng, _lngLat.lat];
}
