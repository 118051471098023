// core version + navigation, pagination modules:
import Swiper, { Pagination } from 'swiper';

document.addEventListener("turbolinks:load", function () {
  const swiper = new Swiper('.intro-swiper', {
    modules: [Pagination],
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    autoHeight: false,
    loop: false
    // swipe one slide at a time
  });
});