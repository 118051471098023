import * as vis from "./visibility";
import { map, addLocationButton } from "./map";

const getCoordinatesAndGoToSubmissionForm = (e) => {
  const coordinates = [e.lngLat.lng, e.lngLat.lat];
  window.location.href = `/locations/new?coordinates=${coordinates}`;
};

export const allowLocationAddition = (e) => {
  map.getCanvas().style.cursor = "crosshair";
  map.on("click", getCoordinatesAndGoToSubmissionForm);
  vis.showById("info");
  addLocationButton.innerHTML = "Cancel";
  addLocationButton.removeEventListener("click", allowLocationAddition);
  addLocationButton.addEventListener("click", cancelLocationAddition);
};

const cancelLocationAddition = (e) => {
  map.getCanvas().style.cursor = "";
  map.off("click", getCoordinatesAndGoToSubmissionForm);
  addLocationButton.innerHTML = "Add Location";
  addLocationButton.removeEventListener("click", cancelLocationAddition);
  addLocationButton.addEventListener("click", allowLocationAddition);
  vis.hideById("info");
};
