/* 

  Line by line translated from Ruby to JavaScript
  Using Phind.com
*/

export function parameterize(string, separator = "-", preserveCase = false) {
  // Replace accented chars with their ASCII equivalents.
  // No built-in transliteration method in JavaScript
  // Turn unwanted chars into the separator.
  let parameterizedString = string.replace(/[^a-z0-9\-_]+/gi, separator);

  if (separator !== null && separator !== "") {
    let reDuplicateSeparator, reLeadingTrailingSeparator;
    if (separator === "-") {
      reDuplicateSeparator = /-{2,}/;
      reLeadingTrailingSeparator = /^-|-$/;
    } else {
      const reSep = separator.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'); // escape for regex
      reDuplicateSeparator = new RegExp(`${reSep}{2,}`, 'g');
      reLeadingTrailingSeparator = new RegExp(`^${reSep}|${reSep}$`, 'g');
    }
    // No more than one of the separator in a row.
    parameterizedString = parameterizedString.replace(reDuplicateSeparator, separator);
    // Remove leading/trailing separator.
    parameterizedString = parameterizedString.replace(reLeadingTrailingSeparator, "");
  }

  if (!preserveCase) {
    parameterizedString = parameterizedString.toLowerCase();
  }

  return parameterizedString;
}
