import { map } from './map';
import { mapActivateFeature } from './mapInteractionHelpers';

const activateFeatureForLocationShow = () => {
  // get the feature from the map
  const locations = map.getLayer('locations-points');
  // get the id from the url
  const url = window.location.href;
  const id = url.split('/').pop();
  // get the feature from the map
  const feature = locations.source._data.features.find(feature => feature.properties.id == id);
  mapActivateFeature(feature);
}

export default activateFeatureForLocationShow;