import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'learn', 'historical', 'sites', 'plan' ]

  connect() {
  }

  toggle(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.lastOpen && this.lastOpen != e.target) {
      let target = this.getDropdownTarget(this.lastOpen);
      target?.classList.add('closed');
    }


    this.lastOpen = e.target;
    let currentTarget = this.getDropdownTarget(e.target);

    currentTarget?.classList.toggle('closed');
  }


  getDropdownTarget(element) {
    const name = element.getAttribute('data-toggle');

    let target = this[name + 'Target'];

    if (!target) {
      console.error('Could not find target for dropdown: ' + name);
      return;
    } 

    return target;
    
  }
}
