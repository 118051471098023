// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "trix"
import "@rails/actiontext"
import jquery from "jquery"
require("@nathanvda/cocoon")

Rails.start()
Turbolinks.start()
ActiveStorage.start()


require('foundation-sites');
require('app/map');
require('app/periodsMap');

require('app/loggedIn');

const startFoundation = () => {
  $(document).foundation();
}

$(document).on('turbolinks:load', startFoundation);

require("trix")
require("@rails/actiontext")

require("app/submitFormOnChange");
require("app/square");
require("app/direct_uploads");
require("app/triggerFileAttached");
require("app/setDataIndexForMediaInputs");
require("app/shiftAddAssociationButtonAfterInsert");
require("app/mapInfoBox");
require("app/overrideSignupsForm");
require("app/periodsSlider");
require("app/uploadSlider");
require("app/locationsIntroSlider");
require("app/homeSwiper");
require("app/trixEdtitorLinksOverride");
require("app/clickToMaximise")
require("app/collapsible")



import overrideSignupsForm from '../app/overrideSignupsForm';
import uploadSlider from '../app/uploadSlider';
import clickToMaximise from '../app/clickToMaximise';
import collapsible from '../app/collapsible';
import activateFeatureForLocationShow from '../app/activateFeatureForLocationShow';

export {
  overrideSignupsForm,
  uploadSlider,
  clickToMaximise,
  startFoundation,
  collapsible,
  activateFeatureForLocationShow
}





import "controllers"
