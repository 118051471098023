// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination } from 'swiper';

export let swiper;

document.addEventListener("turbolinks:load", function () {
  swiper = new Swiper('.my-swiper', {
    modules: [Navigation, Pagination],
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoHeight: true,
    loop: false,
    on: {
      slideChangeTransitionEnd: setPeriodName,
      afterInit: onAfterInit,
    }
  });


  let swiperControls = document.querySelector('.slider-controls');
  window.addEventListener('scroll', () => {
    if (swiperControls) {
      swiperControls.style.top = 0;
      // swiperControls.style.backgroundColor = window.scrollY > 100 ? 'rgba(55,74,98,0.95)' : '';
    }
  })

  document.addEventListener('click', (e) => {
    if(e.target.classList.contains('slide-changer')) {
      if (e.target.classList.contains('next')) swiper.slideNext();
      else swiper.slidePrev();
      window.scrollTo({top: 0, left: 0, behaviour: 'smooth'});
    }
  })

});

const onAfterInit = () => {
  setActiveSlide();
  setPeriodName();
};

const setActiveSlide = (swiper) => {
  // get the anchor from the url
  const anchor = window.location.hash.replace('#', '');
  if(!anchor) return; // skip if none specified

  // find a slide with the id `period-slide-${anchor}`
  const slide = document.querySelector(`#period-slide-${anchor}`)
  if(!slide){
    console.warn(`No slide found with id 'period-slide-${anchor}'`);
    return;
  };
  
  const { index } = slide.dataset;
  if(!index) {
    console.warn(`No index found for slide with id 'period-slide-${anchor}'`);
    return;
  };
  
  // Set as the active slide
  swiper = document.querySelector('.swiper').swiper;
  if(!swiper) {
    console.warn(`No swiper found`);
    return;
  };
  
  swiper.slideTo(index, 0);
}

const setPeriodName = () => {
  let active = document.querySelector('.swiper-slide-active').dataset.name;
  // make the class name same as ruby .downcase.parameterize
  let className = active.toLowerCase().replace(/ /g, '-');
  const name = document.getElementById('slider-period-name');
  if (name) {
    name.innerText = active;
    name.classList = `bg-${className}`
  }
};