import * as ajax from './ajax';

const overrideSignupsForm = () => {
  // get #signup-form
  const signupsForm = document.getElementById('signup-form');
  if(signupsForm){
    // get submit input inside form
    const submitInput = signupsForm.querySelector('button[type="submit"]');
    // add click event listener to submit input
    submitInput.addEventListener('click', (e) => {
      e.preventDefault();
      ajax.sendData(
        new FormData(signupsForm),
        signupsForm.action + '.json',
        (res) => {
          if(res.success) renderSuccess();
          else if(res.errors.length > 0){
            renderSignupErrors(res.errors);
          } else {
            renderError();
          }
        },
        (e) => {
          console.log(e);
          renderError();
        }
      )
    })
  }
}

const renderSuccess = () => {
  const signupsForm = document.getElementById('signup-form');
  signupsForm.innerHTML = `
    <p class="nmb">
      <span class="bold">Thanks! </span>
      <br/>
      We've emailed you to confirm your subscription. To finish subscribing, please click the link in the email.
      <a href="mailto:tech@icecreamarchitecture.com">Get in touch</a> if you don't see it.
    </p>
  `
}

const renderError = () => {
  const signupsForm = document.getElementById('signup-form');
  signupsForm.innerHTML = `
    <h4>Oh no!</h4>
    <p>Somethings gone wrong. Please refresh the page and try again.</p>
    <p>
      If the problem persists, please contact 
      <a>(href="mailto:tech@icecreamarchitecture") us</a>
    </p>
  `
}


const renderSignupErrors = (errors) => {
  const signupsForm = document.getElementById('signup-form');
  let errorList = signupsForm.querySelector('.error-list');
  if(!errorList) errorList = document.createElement('ul');
  else errorList.innerHTML = '';
  errorList.classList.add('error-list');
  errors.forEach((error) => {
    const li = document.createElement('li');
    li.innerText = error;
    errorList.appendChild(li);
  })
  signupsForm.insertBefore(errorList, signupsForm.firstElementChild);
}

export default overrideSignupsForm;
