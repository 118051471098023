import * as vis from './visibility';
import { hideMediaFields, enableMediaButtons } from './addMediaItems';
import fileData from './fileValidationData';
import MyUploader from './MyUploader';

let CURRENT_INDEX;

const FILE_TYPES = { v: 'video', i: 'image', a: 'audio', t: 'application' }

// HTML Templates for File Preview ////////////////////////////////////////////

const progressHTML = () => {
  const container = document.createElement('div');
  container.id = 'progress-bar-wrapper';
  
  container.innerHTML = `
    <div class='mp'>
      <div class="text-center">
      <h4 class="inline">Uploading...</h4>
      <i class="fa fa-cog fa-spin" aria-hidden="true" class='smr'></i>
      </div>
      <label class="nm text-center" id="progress-label">0%</label>
      <div id="uploadProgressBar"class="progress" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuetext="50 percent" aria-valuemax="100">
        <div class="progress-meter" style="width: 0%"></div>
      </div>
    </div>
  `
  
  return container;
}

const imageHTML = (id, index) => {
  return `
    <img id="image-preview-thumbnail-${CURRENT_INDEX}" class="image-preview-thumbnail media-preview-thumbnail" data-signed-id="${id}" />
  `;
}

const videoHTML = (id, index) => {
  return `
    <video id="video-preview-thumbnail-${CURRENT_INDEX}" class="video-preview-thumbnail media-preview-thumbnail" controls data-signed-id="${id}">
    </video>
   `;
}

const audioHTML = (id, index) => {
  return `
    <audio id="audio-preview-thumbnail-${CURRENT_INDEX}" class="audio-preview-thumbnail media-preview-thumbnail border-radius" controls data-signed-id="${id}" />
   `;
}

const textHTML = (id, index) => {
  // NOTE - use 'application-preview-...' rather than document or text, as mime prefix is application
  return `
    <object data-signed-id="${id}">
      <embed id="application-preview-thumbnail-${CURRENT_INDEX}" class='media-preview-thumbnail'/>
    </object>
   `;
}

const fileHTML = (file, id=null, index=0) => {
  const html = {
    image: imageHTML(id, index),
    video: videoHTML(id, index),
    audio: audioHTML(id, index),
    application: textHTML(id, index),
  }

  const type = getFileType(file);
  const div = document.createElement('div');
  div.classList.add('smb', 'relative', 'preview-thumbnail-container', `${type}-preview-container`)
  div.innerHTML = html[type];

  const fileName = document.createElement('label');
  fileName.innerText = file.name;
  fileName.classList.add('h6');
  div.appendChild(fileName);
  return div;
}

const validateAndPreview = (e) => {
  const file = e.detail.file;
  CURRENT_INDEX = e.target.getAttribute('data-index');

  if(validateFile(file)){
    showValidFeedback(e.target);
    uploadFileAndRenderPreview(file, e.target);
    e.target.value = null; // clear File inpue - NOTE - do we need to?
    refreshUI();
  } else {
    showValidFeedback(e.target, false);
  }
}

// Validate single files
const validateFile = (file) => {
  const sizeValid = validateSize(file);
  const typeValid = validateType(file);
  return sizeValid && typeValid
}

const uploadFileAndRenderPreview = (file, input) => {
  const url = input.dataset.directUploadUrl;
  const uploader = new MyUploader(file, url);
  //  insert progress HTML
  document.querySelector('body').appendChild(progressHTML());
  
  let submitButton = initializeSubmitButton();
  submitButton.disabled = true;
  document.querySelector('button[type="submit"]').disabled = true;
  uploader.upload()
    .then(blob => {

      // get the hidden field next to the file input and add the signed id there
      const hiddenField = input.nextElementSibling;
      hiddenField.setAttribute("value", blob.signed_id);

      // Render the preview on the form
      renderPreviewWithId({
        file: file,
        id: blob.signed_id  
      }, input);

      vis.hideById('progress-bar-wrapper');
      removeProgress()
      submitButton.disabled = false;
    }, error => {
      console.log(error);
      const errorMessage = input.parentElement.querySelector('.upload-error');
      vis.show(errorMessage);
      vis.hideById('progress-container');
    });
}


// create a function to initialize the submit button
// submit button can either be a button or an input

const initializeSubmitButton = () => {
  let submitButton = document.querySelector('button[type="submit"]');
  if(submitButton == null){
    submitButton = document.querySelector('input[type="submit"]');
  }

  return submitButton;
}



// The same file input field is used to add images, audio, videos or documents
// Therefore, the name will always be item[media_items_attributes][0][<TYPE>]
// We need to increment the index - 0 - based upon number of existing
// media items and those that will be added, i.e elements in #previews and
// #media-items.
const setMediaItemIndexInName = (name) => {
  const index = getFileCount();
  return name.replace('0', index); // TODO add existing media_items
}



// Render a preview of each new file in #previews
// Doing this file by file reduces amount of elements that need rendering
const renderPreviewWithId = (fileWithId, input=undefined) => {
  const { file, id } = fileWithId;
  const index = getFileCount();
  renderPreview(file, id, index, input);
  renderPreviewFile(file, index, input);
}

const getFileType = (file) =>{
  for (let k in FILE_TYPES){
    let type = FILE_TYPES[k];
    if(file.type.startsWith(type)) return type;
  }
}

const validateSize = (file) => {
  const type = getFileType(file);
  const { limit } = fileData[type]
  return file.size < limit;
}

const validateType = (file) => {
  const type = getFileType(file);
  const { types } = fileData[type]
  return types.includes(file.type);
}

// Render HTML for preview.
// NOTE - This is just the container, source is set in renderPreviewFile()
const renderPreview = (file, id=null, index=0, input=undefined) => {
  if(input){
    const preview = input.parentElement.querySelector('#previews');
    if(preview) preview.innerHTML = '';
    preview.appendChild(fileHTML(file, id, index))
  } else {
    const previews = document.querySelectorAll(`.preview`);
    previews[CURRENT_INDEX].innerHTML = '';
    previews[CURRENT_INDEX].appendChild(fileHTML(file, id, index))
  }
}

// Insert file from form into preview HTML element
const renderPreviewFile = (file, index=0, input=undefined) => {

  // Select HTML element to add file to
  const type = getFileType(file);

  if(input){
    var div = input.parentElement.querySelector(`.${type}-preview-thumbnail`);
  } else {
    var div = document.getElementById(`${type}-preview-thumbnail-${index}`);
  }

  // Create callback to insert file when loaded
  let action;

  // IMAGE
  if(type === 'image'){
    action = () => { div.src = reader.result; }
  } else if(type === 'text') {
    // TEXT uses <embed> inside <object> requiring a data attr
    action = () => {
      div.src = reader.result;
      div.parentElement.data = reader.result;
      div.type = file.type;
    }
  } else {
    // VIDEO and VIDEO
    action = () => {
      div.src = reader.result;
      div.type = file.type;
    }
  }

  // Read file with FileReader and set onLoad callback (see above)
  if (div) {
    var reader = new FileReader();
    reader.onload = action;
    reader.readAsDataURL(file);
  }
}

const getModelType = () => {
  const type = document.querySelector('body').classList[0];
  // Singularize if necessary, i.e 'items' => 'item'
  if(type[type.length - 1] === 's'){
    return type.slice(0, type.length - 1);
  } else {
    return type;
  }
}

const refreshUI = () => {
  hideMediaFields();
  enableMediaButtons();
}

// Calcuate total files from number of previews
const getFileCount = () => {
  const thumbs = document.querySelectorAll('.media-preview-thumbnail');
  return thumbs.length;
}

const getPreviewDiv = (file) => {
  const typedPreview = document.querySelector(`#${getFileType(file)}-preview`);
  const previews = document.querySelector('#previews');
  return typedPreview ? typedPreview : previews;
}

const getFieldContainer = (file) => {
  return document.querySelector(
    `.${getFileType(file)}-field-container`
  );
}

const showValidFeedback = (fileInput, isValid = true) => {
  const valid = fileInput.parentElement.querySelector('.valid-message');
  const invalid = fileInput.parentElement.querySelector('.invalid-message');
  if(isValid){
    vis.show(valid);
    vis.hide(invalid);
  } else {
    vis.show(invalid);
    vis.hide(valid);
  }
}

const removeProgress = () => {
  setTimeout(
    () => {
      const progress = document.querySelector('#progress-bar-wrapper');
      progress.parentElement.removeChild(progress);
    },
    1000
  )
  
}

// Validate file when selected
addEventListener("direct-uploads:file-attached", validateAndPreview)
