import mapboxgl from "mapbox-gl";
import * as mapData from "./mapData";
import { swiper } from './periodsSlider';
import { kebabCase } from 'lodash';
import * as mapHelpers from "./mapInteractionHelpers";

let mapContainer;
export let map;

// all periods names which are then used in functions withou 
// having to fetch the data again or passing as parametres
let periodsNames = []

const initializeMap = () => {
  if (!mapboxgl.supported()) return;

  mapContainer = document.getElementById("periods-map");
  if (!mapContainer) return;

  map = mapData.setupMap("periods-map");
  map.addControl(new mapboxgl.NavigationControl(), "bottom-left");
  map.on('load', handleMapLoad);
};


/* when the map is loaded, fetch the data and add the layers
  for each period
  hide all but the current period
  which is taken from the swiper slider
  add event listener to each period layer
  add event listener to the swiper slider
  when icon is clicked, scroll to the story
  when the slide changes, hide all but the current period

*/
const handleMapLoad = async () => {
  mapHelpers.setMapZoom(map);

  let response = await fetch("/periods.json");
  let periods = await response.json();
  periodsNames = await periods.features.map((period) => period.name);

  addPeriodsLayers(periods);
  periodsNames.forEach((period) => {
    map.on('click', period, handleLocationClick);
  });

  let currentName = document.querySelector('.swiper-slide-active').dataset.name;
  hideAllButCurrentPeriod(currentName, periodsNames);
  swiper.on('slideChangeTransitionEnd', handleSwiperSlideChange);

  mapHelpers.toggleScrollBtn();
};


/* 
  When the icon is clicked, get the story data
  get the period and location and create the id
  for this section
  note that in the html each story is grouped by period
  and within a div with id period-location
*/

let locationCurrentlyClicked = false;

const handleLocationClick = (e) => {
  if(locationCurrentlyClicked) return;
  locationCurrentlyClicked = true;

  let story = e.features[0].properties;

  let period = kebabCase(story.period);
  let location = kebabCase(story.location)

  const slide = document.querySelector(`.swiper-slide[data-name="${story.period}"]`);

  let id = `${period}-${location}`;
  const element = slide.querySelector(`#${id}`);
  element.classList.add('active');

  // get the bounding client rect of the slider controls
  const sliderControlsClientRect = document.getElementsByClassName('slider-controls')[0].getBoundingClientRect();

  const scrollTo = element.getBoundingClientRect().y - (sliderControlsClientRect.height + 20);

  window.scrollTo({
    top: scrollTo,
    left: 0,
    behavior: 'smooth'
  })

  setTimeout(
    () => { locationCurrentlyClicked = false; },
    1000
  );
  
};

const handleSwiperSlideChange = () => {
  let currentName = document.querySelector('.swiper-slide-active').dataset.name;
  hideAllButCurrentPeriod(currentName, periodsNames);
}

// filter the periods and hide all but the current one
const hideAllButCurrentPeriod = (currentPeriod, allPeriods) => {
  const otherPeriods = allPeriods.filter((period) => period !== currentPeriod);

  // fade out other periods
  otherPeriods.forEach((period) => {
    map.setPaintProperty(period, 'icon-opacity', 0);
  });

  // fade in current period
  map.setPaintProperty(currentPeriod, 'icon-opacity', 1);
}


const addPeriodsLayers = (periods) => {
  periods.features.forEach((period) => {
    addStoriesLayer(period);
  });
};

const addStoriesLayer = (period) => {
  map.addSource(period.name, {
    "type": "geojson",
    "data": period.features,
  });

  map.addLayer({
    "id": period.name,
    "type": "symbol",
    "source": period.name,
    "layout": {
      "icon-image": "{icon}",
      "icon-anchor": "bottom",
      "symbol-z-order": "viewport-y",
      "icon-size": ['interpolate', ['linear'], ['zoom'], 12, 1, 20, 1.5],
      "icon-allow-overlap": true,
    },

    "transition": {
      "duration": 1000,
      "delay": 0
    },

    "paint": {
      "text-color": "#ffffff",
      "icon-opacity": 0, // the opacity is set to 0 when the map is loaded
    },

    'filter': ['==', '$type', 'Point']
  });
}

document.addEventListener('turbolinks:load', initializeMap);