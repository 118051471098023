import { DirectUpload } from '@rails/activestorage';

export default class MyUploader {

  constructor(file, url) {
    this.directUpload = new DirectUpload(file, url, this)
  }

  upload(){
    return new Promise((resolve, reject) => {
      this.directUpload.create((error, blob) => {
        if(error){
          // handle Error
          reject(error)
        } else {
          resolve(blob)
        }
      })
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    this.addProgressListener(request);
  }

  addProgressListener(request){
    request.upload.addEventListener(
      "progress",
      event => this.directUploadDidProgress(event)
    )
  }

  directUploadDidProgress(event) {
    // Use event.loaded and event.total to update the progress bar
    let percent = ((event.loaded / event.total) * 100).toFixed(0)
    const progresBar = document.getElementById('uploadProgressBar');
    const bar = document.getElementById('progress-amt');
    if(bar) bar.style.width = percent + '%';
    if(progresBar) progresBar.getElementsByClassName('progress-meter')[0].style.width = percent + '%';
   
    const label = document.getElementById('progress-label');
    if(label) label.innerText = percent + '%';
    // update progress
  }

  resetProgressBar(){
    const bar = document.getElementById('progress-amt');
    if(bar) bar.style.width = '0%';

    const label = document.getElementById('progress-label');
    if(label) label.innerText = '0%';
  }

}
