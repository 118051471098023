import Rails from '@rails/ujs';
import mapboxgl from "mapbox-gl";
import { map } from "./map";
import { mapOptions, desktopView, mobileView, bounds } from './mapData';
import { hideById, showById, fadeOutById, fadeInById } from './visibility';

import * as loggedIn from './loggedIn';
const navigation = new mapboxgl.NavigationControl();
const { center, zoom } = mapOptions;


/* 
  @function mapActivateFeature
  @description This function gets fired every time a user clicks on a location on the map. It does the following:
  zooms to the location
  removes any other locations from the map
  removes the navigation controls
  removes click events from the map
  if the user is logged in, it hides the add location button
  and it shows the scroll down button

*/

export const mapActivateFeature = (feature) => {
  map.flyTo({ center: feature.geometry.coordinates, zoom: 15 });
  map.setFilter('locations-points', ['==', 'id', feature.properties.id]);
  map.setFilter('locations-text', ['==', 'id', feature.properties.id]);
  removeNavigationControls();
  document.getElementById('map').classList.add("ignore-click");

  hideById("addLocation");
  toggleScrollBtn();

  setTimeout(
    () => map.setMaxBounds(map.getBounds()),
    2000
  )
};

/* 
  @function mapDeactivateFeature
  @description This function gets fired every time a user clicks on the back button. It does the following:
  zooms back to the original map view
  adds all locations back to the map
  adds the navigation controls
  adds click events to the map
  if the user is logged in, it shows the add location button
  and it hides the scroll down button
*/

export const mapDeactivateFeature = () => {
  map.fitBounds(getView());
  map.setFilter('locations-points', ['!=', 'id', '']);
  map.setFilter('locations-text', ['!=', 'id', '']);
  addNavigationControls();
  document.getElementById('map').classList.remove("ignore-click");
  loggedIn && showById("addLocation");
  toggleScrollBtn();
};


// Adds the navigation controls to the map also used in map.js
export const addNavigationControls = () => {
  map.addControl(navigation, "bottom-left");
  
}

// Removes the navigation controls from the map
const removeNavigationControls = () => {
  map.removeControl(navigation);
}

// Fires remote request to locations#show by using the hidden link in location/index.html.haml
export const fireLocationLink = (feature) => {
  let locationLink = document.getElementById("location-link");
  locationLink.href = `/locations/${feature.properties.id}`;
  Rails.fire(locationLink, 'click');
};

// it shows the top section of the location page with the current location's title
export const showTitleSection = (feature) => {
  let titleSection = document.getElementById('title-section');
  document.getElementById('title').innerText = feature.properties.title;
  titleSection?.classList.remove('hidden');
};

//  this function will be called when the user clicks on the back button
// it will call mapDeactivateFeature  and hide the location section
export const handleBackClick = (wait = false, map) => {
  map.setMaxBounds(bounds); // reset the max bounds to default
  
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });

  const action = () => {
    mapDeactivateFeature();
    hideById("location-section");
    fadeInById("location-section");
    document.getElementById("title-section").classList.add("hidden");
  }

  if(wait) setTimeout(action, 600);
  else action();
};


// this will either show or hide the scroll down button
export const toggleScrollBtn = () => {
  let scrollBtn = document.getElementById("scroll-down-arrow");
  scrollBtn?.classList.toggle("visually-hidden");
  scrollBtn?.classList.toggle("fade-in");
  addEventListenerToScrollBtn();
};

// adds the event listener to the scroll down button so that when the user clicks on it, it will scroll down to the location section
const addEventListenerToScrollBtn = () => {
  let scrollBtn = document.getElementById("scroll-down-arrow");
  scrollBtn?.addEventListener("click", () => {
    window.scrollTo({
      top: window.innerHeight,
      left: 0,
      behavior: 'smooth'
    });
  });
};


const disableTouchEventsOnMap = () => {
  document.querySelector(".mapboxgl-canvas").style.pointerEvents = "none";
}

const enableTouchEventsOnMap = () => {
  document.querySelector(".mapboxgl-canvas").style.pointerEvents = "auto";
}

// if window width is smaller than 640px (mobile), zoom out
export const setMapZoom = (mapParameter = undefined) => {
  let thisMap = mapParameter ? mapParameter : map;
  
  // disable all clicks on map for 1.5 seconds
  // disableTouchEventsOnMap();
  thisMap.fitBounds(getView());
  // setTimeout(enableTouchEventsOnMap, 1500)
}

const getView = () => {
  if (window.innerWidth < 640) return mobileView;
  else return desktopView;
}
